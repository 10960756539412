<template>
  <div>
    <cpat-create-page></cpat-create-page>
  </div>
</template>

<script>
import CpatCreatePage from './CpatCreatePage.vue';

export default {
  components: {
    CpatCreatePage,
  },

  data() {
    return {};
  },
};
</script>
